import React from 'react';
import LogoSVG from '../components/LogoSVG';
import { auth } from '../Firebase';
// import { signInWithGoogle } from '../Firebase';

function Home({isAuth}) {
  if (isAuth) {
    console.log('isAuth:', isAuth)
  }
  return (
    <div className="home-page ">
      <LogoSVG />
      <Greeting />
      <div className="current-user">
        {(isAuth) 
          ? <h3 className='m-3'>Welcome {isAuth.displayName}</h3>
          : <></>
        }
      </div>
    </div>
  )
}

const Greeting = () => {
  return (
    <div className="greeting-wrapper">
      <div className="greeting">
        <div className="greeting-container">
          <p className="greeting-container-text">Hello,</p>
          <ul className="greeting-container-list">
            <li className="greeting-container-list-item">member.</li>
            <li className="greeting-container-list-item">comrade.</li>
            <li className="greeting-container-list-item">honky.</li>
            <li className="greeting-container-list-item">afficionado.</li>
            <li className="greeting-container-list-item">nerd.</li>
            <li className="greeting-container-list-item">friend.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}


export default Home